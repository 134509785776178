import type { SVGProps } from 'react';
import type { IconType } from './Icons';
import IconPath from './Icons';

type TProps = SVGProps<SVGSVGElement> & {
  iconType: IconType;
  style?: object;
  fill?: string;
  onClick?: any;
  role?: string;
  className?: string;
  width?: string;
  height?: string;
  viewBoxWidth?: string;
  viewBoxHeight?: string;
};

const SVGIcon = ({
  iconType,
  style = {},
  fill,
  className = '',
  width = '24',
  height = '24',
  viewBoxWidth = '24',
  viewBoxHeight = '24',
  onClick = () => {},
}: TProps) => (
  <svg
    width={width}
    style={style}
    onClick={onClick}
    height={height}
    className={`fo-icon ${className}`}
    fill={fill || 'currentColor'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <IconPath iconType={iconType} />
  </svg>
);
export default SVGIcon;
