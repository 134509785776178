import type { ButtonSizeType, ButtonVariantType } from '~/types/buttons';
import { ButtonLink } from './ButtonLink';
import { Button } from './Button';
import type { CSSProperties } from 'react';

export type DataAttribute = {
  [key: `data-${string}`]: unknown;
};

export type AriaAttribute = {
  [key: `aria-${string}`]: unknown;
};

type CommonProps = {
  id?: string;
  extraClasses?: string;
  variant?: ButtonVariantType;
  size?: ButtonSizeType;
  dataAttributes?: DataAttribute;
  ariaAttributes?: AriaAttribute;
  children?: React.ReactNode;

  style?: CSSProperties;
  disabled?: boolean;
};

export type ButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  role?: 'button' | 'link';
  isIconButton?: boolean;
  floating?: boolean;
  name?: string;
  disabled?: boolean;
  reloadDocument?: never;
  to?: never;
  target?: never;
  rel?: never;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
} & CommonProps;

export type ButtonLinkProps = {
  to: string;
  target?: string;
  rel?: string;
  reloadDocument?: boolean;
  name?: never;
  type?: never;
  role?: never;
  isIconButton?: never;
  floating?: never;
  disabled?: never;
  onClick?: () => void;
} & CommonProps;

export type ButtonWrapperProps = ButtonProps | ButtonLinkProps;

export const variantMap = {
  primary: 'btn-fo-primary ',
  primaryOutline: 'btn-fo-outline-primary',
  primaryLight: 'btn-fo-light-primary',
  primaryWhite: 'btn-fo-white-primary',
  primaryWhiteOutline: 'btn-fo-primary-white-outline',
  primaryGhost: 'btn-fo-ghost-primary',
  navPrimaryLightOutline: 'btn-nav-outline-primary-light',
  accent: 'btn-fo-accent',
  accentOutline: 'btn-fo-outline-accent',
  accentLight: 'btn-fo-light-accent',
  accentGhost: 'btn-fo-ghost-accent',
  tertiary: 'btn-fo-tertiary',
  tertiaryGhost: 'btn-fo-ghost-tertiary',
  tertiaryOutline: 'btn-fo-outline-tertiary ',
  tertiaryGreyOutline: 'btn-fo-outline-tertiary-grey',
  gradientGhostAccent: 'btn-fo-ghost-accent-gradient',
  floatingPrimaryLight: 'btn-fo-floating-primary-light',
};

const ButtonWrapper = ({
  id,
  extraClasses = '',
  to,
  children,
  onClick,
  variant,
  size,
  type,
  style,
  target,
  rel,
  reloadDocument,
  name,
  isIconButton,
  floating,
  disabled,
  dataAttributes,
  ariaAttributes,
}: ButtonWrapperProps) => {
  const isLink = !!to;

  return (
    <>
      {isLink && (
        <ButtonLink
          variant={variant}
          extraClasses={extraClasses}
          id={id}
          size={size}
          style={style}
          to={to}
          target={target}
          rel={rel}
          onClick={onClick}
          dataAttributes={dataAttributes}
          ariaAttributes={ariaAttributes}
          reloadDocument={reloadDocument}
        >
          {children}
        </ButtonLink>
      )}

      {!isLink && (
        <Button
          variant={variant}
          extraClasses={extraClasses}
          id={id}
          name={name}
          disabled={disabled}
          size={size}
          style={style}
          type={type}
          isIconButton={isIconButton}
          floating={floating}
          onClick={onClick}
          dataAttributes={dataAttributes}
          ariaAttributes={ariaAttributes}
        >
          {children}
        </Button>
      )}
    </>
  );
};

export default ButtonWrapper;
