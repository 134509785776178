import type { ButtonSizeType } from '~/types/buttons';
import { ButtonProps, variantMap } from './ButtonWrapper';

export const Button = ({
  id,
  extraClasses = '',
  style,
  children,
  type,
  role,
  name,
  variant = 'primary',
  size = 'md',
  floating = false,
  isIconButton = false,
  onClick,
  disabled = false,
  dataAttributes,
  ariaAttributes,
}: ButtonProps) => {
  const sizeMap = (size: ButtonSizeType) => (size === 'md' ? '' : `btn${isIconButton ? '-icon' : ''}-${size}`);

  const btnClasses = `${variantMap[variant]} ${sizeMap(size)} ${extraClasses} ${floating ? '-floating' : ''}`;

  return (
    <button
      type={type}
      role={role}
      id={id}
      name={name}
      disabled={disabled}
      className={`btn ${isIconButton ? 'btn-icon' : ''} ${btnClasses}`}
      onClick={onClick}
      style={style}
      {...ariaAttributes}
      {...dataAttributes}
    >
      {children}
    </button>
  );
};
