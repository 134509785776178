import { Link } from '@remix-run/react';
import { variantMap, type ButtonLinkProps } from './ButtonWrapper';

export const ButtonLink = ({
  id,
  extraClasses = '',
  style,
  to,
  rel,
  reloadDocument = false,
  target,
  variant = 'primary',
  size = 'md',
  children,
  onClick,
  dataAttributes,
  ariaAttributes,
}: ButtonLinkProps) => {
  const sizeMap = {
    sm: 'btn-sm',
    md: '',
    lg: 'btn-lg',
    xl: 'btn-xl',
    xxl: 'btn-xxl',
  };

  return (
    <Link
      className={`btn ${variantMap[variant]} ${sizeMap[size as keyof typeof sizeMap]} ${extraClasses}`}
      to={to}
      rel={rel}
      reloadDocument={reloadDocument}
      target={target}
      id={id}
      onClick={onClick}
      style={style}
      {...ariaAttributes}
      {...dataAttributes}
    >
      {children}
    </Link>
  );
};
